module.exports = {
  siteTitle: 'Comercia Tu Producto', // <title>
  manifestName: 'Identity',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#657db9',
  manifestThemeColor: '#657db9',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-identity/`, // This path is subpath of your hosting https://domain/portfolio
  authorName: 'Javier Hérnandez Mendoza',
  heading: 'Ejecutivo comercial',
  text: 'Contáctame:',
  // social
  socialLinks: [
    {
      icon: 'fa-whatsapp',
      name: 'Celular',
      url:
        'https://wa.me/+5215529219690/?text=Hola Javier, me interesa saber de tu oferta de productos famacéuticos',
    } /*
    {
      icon: 'fa-globe',
      name: 'WebPage',
      url: 'https://www.comerciatp.com/',
    },*/,
    {
      icon: 'fa-address-card',
      name: 'Tarjeta de direcciones',
      url:
        'https://res.cloudinary.com/farmazone/raw/upload/v1629528008/vcards/javier_hernandez.vcf',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:javier.hernandez@comerciatp.com',
    } /* 
    { 
      icon: 'fa-linkedin',
      name: 'linkedin',
      url: 'http://linkedin.com/in/abner-sánchez-fiz-a8018a105'
    },*/,
  ],
};
